import { NotFound } from "features/misc";
import { lazyImport } from "utils/lazyImport";

const { LandingRoutes } = lazyImport(
  () => import("features/landing"),
  "LandingRoutes"
);

export const publicRoutes = [
  {
    path: "/*",
    element: <LandingRoutes />,
  },
  { path: "*", element: <NotFound /> },
];
